.inputStyle {
  width: 40px;
  height: 40px;
  margin: 0 5px;
  font-size: 18px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.otp-container {
  display: flex;
  justify-content: center;
  margin-top: 8px;
  width: fit-content;
}

.otp-container input {
  box-sizing: border-box;
  text-align: center;
  width: 43px !important;
  height: 45px;
  background: #ffffff;
  border: 1px solid #cbc9c9;
  border-radius: 4px;
  &:focus {
    border-color: #3d5cb8 !important;
  }
  &:focus-visible {
    border-color: #3d5cb8 !important;
  }
}

.otp-container input:focus {
  border-color: #66afe9;
  outline: none;
  box-shadow: 0 0 5px rgba(102, 175, 233, 0.6);
}

@media (max-width: 1200px) {
  .inputStyle,
  .otp-container input {
    width: 35px;
    height: 35px;
    font-size: 16px;
  }

  .otp-container input {
    width: 33px !important;
    height: 33px;
  }
}
