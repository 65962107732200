.display-field {
  height: 3rem;
}
.common-vessel-details {
  .accordion-item {
    border: 1px solid #d2d2d2 !important;
    border-radius: 0.375rem !important;
    overflow: hidden;
  }
}
