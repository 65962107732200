.stage-verification {
  label {
    color: black;
    font-weight: bold;
  }
  .list-group > .list-group-item:first-child {
    background-color: #f5f5f5;
  }
}

.propulsion-engine{
  label{
    font-weight: lighter !important;
  }
}
