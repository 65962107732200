.text-input {
  margin-top: 5px;
  background-color: var(--primary-color);
}
.text-box {
  margin-top: 8px;
}
.error-message {
  color: var(--red);
  font-size: 12px;
}
.pagination-container {
  margin-top: 16px;
  float: right;
}
// .table-container {
//   margin-top: 2vh;
//   margin-bottom: 2vh;
// }
.breadcrumbs {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.loader {
  display: flex;
  justify-content: center;
  align-items: "center";
}
.kmb-table th {
  color: #2d2f3a !important;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.kmb-table {
  // border: 1px solid #CBC9C9;
  td {
    color: #5a5c68 !important;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  tr,
  td,
  th {
    border: none;
  }
}

.fade.modal-backdrop.show {
  z-index: 1050;
}
.modal-backdrop + .confirmation-modal {
  z-index: 1090;
}
.confirmation-modal {
  .modal-dialog {
    height: 100%;
    .modal-content {
      position: absolute;
      top: 40%;
      right: 50%;
      transform: translate(50%, -50%);
      border-radius: 16px;
      border: none;
      .modal-header,
      .modal-footer {
        border: none;
      }
      .modal-body {
        text-align: center;
      }
      .modal-footer {
        justify-content: center;
        gap: 0.625rem;
        padding: 2rem;
      }
      .btn-close {
        margin: 0.5px;
      }
    }
  }
}

.simple-captcha canvas {
  margin-left: -20px;
}
.simple-captcha {
  width: 100%;
}

.breadcrum {
  .fa-angle-right {
    margin: 0 5px;
    fill: #2d2f3a;
  }
  span {
    color: #2d2f3a;
    font-size: 14px;
  }
  .inactive {
    color: #7f8087 !important;
  }
}

.multiselect-dropdown {
  height: 3rem;
  .dropdown-container {
    height: 100%;
    .dropdown-heading {
      height: 100%;
    }
  }
}

.multiselect-dropdown.disabled {
  .dropdown-container {
    .dropdown-heading {
      --bs-bg-opacity: 1;
      background-color: rgba(
        var(--bs-secondary-bg-rgb),
        var(--bs-bg-opacity)
      ) !important;
    }
  }
}

.accordion-flush {
  overflow: auto;
  border-radius: 6px;
  .accordion-header {
    .accordion-button {
      font-size: 1.5rem;
      border-radius: 8px;
      box-shadow: none;
    }
    .accordion-button:not(.collapsed) {
      background-color: #fff;
      color: #000;
    }
    .accordion-button:focus {
      border: none;
      box-shadow: none;
    }
  }
}

.layout-page {
  // max-height: 100vh;
  // min-height: 100vh;
  .dashboard-container.container {
    flex-grow: 1;
    flex-direction: column;
    // justify-content: space-between;
    overflow: auto;
    display: flex;
    max-height: 100%;
    padding-top: 20px;
    .card {
      margin-top: 0;
      max-height: 100%;
    }
  }
}

.field {
  font-size: 12px;
}
.display-field {
  font-size: 14px;
  padding-bottom: 20px;
  border-bottom: 1px solid #cbc9c9;
}

.scrollable-horizontal {
  max-height: 43px; /* Set your desired height */
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: hidden; /* Hide vertical scrolling */
  white-space: nowrap; /* Prevent text from wrapping to the next line */
}

.file-selector {
  .file-input-container {
    position: relative;
    width: 100%;
    display: inline-flex;
    align-items: center;

    .file-input {
      border: none;
      background: #e6e5e5;
      width: 100%;
      border-radius: 4px;
    }

    .file-input::-webkit-file-upload-button {
      border: none;
      padding: 15px;
      background-color: transparent;
      color: inherit;
    }
    .file-input::after {
      display: none;
    }
    .file-clip-icon {
      pointer-events: none;
      position: absolute;
      color: #7f8087;
      right: 22px;
    }
  }
}

.dummy-file-container {
  .dummy-file-label {
    height: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #7f8087;
    margin-bottom: 4px; // Adds some spacing below the label
  }
  .dummy-file {
    background: #e6e5e5;
    display: flex;
    width: 100%;
    height: 54px;
    align-items: center;
    border-radius: 4px;
    padding: 0 10px; // Adds padding for inner spacing

    .dummy-file-name {
      flex-grow: 1;
      font-size: 14px;
      line-height: 19px;
      color: #333;
      white-space: nowrap; // Prevents text from wrapping
      overflow-x: auto; // Enables horizontal scrolling
      text-overflow: clip; // Prevents truncation with ellipses
      -ms-overflow-style: none; // Hides scrollbar in IE and Edge
      scrollbar-width: none; // Hides scrollbar in Firefox

      &::-webkit-scrollbar {
        display: none; // Hides scrollbar in WebKit browsers
      }
    }
    
    .dummy-file-delete {
      height: 100%;
      width: 50px;
      border: none;
      background-color: transparent;
      color: #666;
      cursor: pointer; // Makes it clear it's clickable
    }
    .dummy-file-delete:hover {
      color: #f00;
    }
  }
}

.file-uploader {
  .file-input-container {
    position: relative;
    width: 100%;
    display: inline-flex;
    align-items: center;
    .file-input {
      border: none;
      background: #e6e5e5;
      display: none;
      width: 100%;
      height: 48px;
      border-radius: 4px;
    }
    .file-input[type="text"] {
      pointer-events: none;
    }
    .file-input::-webkit-file-upload-button {
      border: none;
      padding: 15px;
      line-height: 0px;
      height: 100%;
      background-color: transparent;
      color: inherit;
    }
    .file-input::after {
      display: none;
    }
    .file-remove-button {
      border: none;
      position: absolute;
      color: #7f8087;
      background-color: transparent;
      right: 6px;
    }
    .file-remove-button:hover {
      color: red;
    }
    .file-clip-icon {
      pointer-events: none;
      position: absolute;
      color: #7f8087;
      right: 22px;
    }
  }
  .file-input::after {
    display: none;
  }
  .file-remove-button {
    border: none;
    position: absolute;
    color: #7f8087;
    background-color: transparent;
    right: 6px;
  }
  .file-remove-button:hover {
    color: red;
  }
  .file-clip-icon {
    pointer-events: none;
    position: absolute;
    color: #7f8087;
    right: 22px;
  }
}

.fileList {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.file_div {
  display: flex;
  align-items: center;
  background-color: #e6e5e5;
  height: 48px;
  width: 100%;
}

.choose-file {
  padding: 10px;
  color: #7f8087;
}

.file_input_text {
  border: none;
  background: #e6e5e5;
  width: 100%;
  // border-radius: 4px;
}
.view-more-link {
  text-align: center;
  color: #3d5cb8 !important;
  cursor: pointer;
}

.required-indicator::after {
  content: "*";
  color: #ef5350;
  font-size: 18px;
}
