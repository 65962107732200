.preapproval-container {
  display: flex;
  padding-top: 50px;
}
.preapproval-request-textbox {
  width: 350px !important;
  height: 130px !important;
}

.preapproval-request-text {
  font-size: 14px;
}

.mb-0 {
  color: #3d5cb8;
  font-size: 14px;
}

.re-submit {
  color: red !important;
  border-color: red !important;
}
.re-submit:hover {
  background: none !important;
}

.field {
  font-size: 12px;
}
.owner-head {
  margin-top: 10px;
  margin-bottom: 20px;
}

.view-more {
  text-align: center;
  color: blue;
  cursor: pointer;
}

.test {
  position: relative;
  display: flex;
  align-items: center;

  .type-file-box {
    border: none;
    border-radius: 4px;
    background: #efefef;
    width: 100%;
  }
  .file-choose-icon {
    position: absolute;
    right: 18px;
    top: 19px;
    color: #7f8087;
  }
  .type-file-box::after {
    display: none;
  }
}
.type-file-box::-webkit-file-upload-button {
  border: none;
  padding: 15px;
  background-color: transparent;
  color: inherit;
}

.react-datepicker-wrapper {
  width: 100%;
  .survey-date-picker {
    margin-top: 2px;
    width: 100%;
    height: 48px;
    border-radius: 4px !important;
    border-color: none !important;
    border: 1px solid #cbc9c9;
    font-size: 14px;
    padding-left: 10px;
  }
  .document-date-picker {
    margin-top: 2px;
    width: 400px;
    height: 50px;
    border-radius: 4px !important;
    border-color: none !important;
    border: 1px solid #cbc9c9;
    font-size: 14px;
    padding-left: 10px;
  }
}

.survey-row {
  // padding-top: 10px;
  padding-left: 10px;
}

.survey-card {
  margin-top: 20px !important;
}

.disply_field {
  border-radius: none !important;
  border: none;
  border-bottom: 1px solid #cbc9c9;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  padding-left: 0;
  margin-bottom: 15px;
}

.survey-app-form {
  padding-top: 20px;
}

.survey-type {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-left: 13px;
}

.survey-field {
  font-size: 14px;
}
.add-button {
  display: flex;
  justify-content: end;
}
.link-button {
  border: none;
  color: #3d5cb8;
  background-color: transparent;
  text-align: left;
  display: block;
  font-weight: bold;
}

.crew-container {
  .crew-card {
    padding: 20px;
  }
  .crew-buttons {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    padding-top: 20px;
  }
}

.main {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-start !important;
  justify-content: center;
}

.profile-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 30px;
}

.image {
  position: relative;
  height: 150px;
  width: 150px;
}

.image .profile-pic {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid #3656ef;
}

.data {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
}

.data h2 {
  font-size: 33px;
  font-weight: 600;
}

.row .info {
  text-align: center;
  padding: 0 20px;
}

.buttons-container {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.contact {
  color: #b5b2b2;
}

.p-name{
  font-size: 20px;
  font-weight: bold;
  padding-top: 50px;

}
.p-font{
  font-size: 18px;
}
.p-mobile{
  font-size: 16px;
}
.profile-pic-icon{
  display: flex;
  gap: 0.5rem;
  color: #3D5CB8;
  font-size: 14px;
  padding-top: 10px;
}

.update-btn{
  box-shadow: none !important;
}
.crew-details-list{
  width: 500px;
}
.crew-display{
  font-size: 18px;
  padding-bottom: 10px;
  border-bottom:  2px solid #D0D0D0;
  margin: 30px 0 0 60px;

}
.crew{
  padding-top: 0px !important;
}
.crew-card{
  padding-bottom: 20px;
}
.crew-forms{
  padding-top: 0px !important;
}
.add-but {
  display: flex;
  justify-content: left;
  color: white;
}
