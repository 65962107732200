.form {
  display: flex;
  padding-top: 30px;
  justify-content: center;
  align-items: center;
}

.buttons-container {
  display: flex;
  justify-content: center;
  padding-top: 30px;
  gap: 16px;
}

.error {
  font-size: 10px;
  color: red;
}
.link-button {
  border: none;
  color: #3d5cb8;
  background-color: transparent;
  text-align: left;
  display: block;
  font-weight: bold;
}
