@import "variables";

h1,
h2,
h3 {
    font-style: normal;
    font-weight: 700 !important;
    color: #2d2f3a;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: -18px 0px;
    font-family: "Manrope", sans-serif;
}
h1 {
    font-size: 32px !important;
    line-height: 80px;
    letter-spacing: 0px;
}
h2 {
    font-size: 24px !important;
    line-height: 64px;
}
h3 {
    font-size: 20px;
    line-height: 48px;
}
h4 {
    font-style: normal;
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 27px !important;
    color: #2d2f3a;
}
label {
    position: inherit;
    left: 22.05%;
    right: 74.3%;
    top: 10.71%;
    bottom: 86.01%;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #7f8087;
}
select {
    box-sizing: border-box;
    height: 48px;
    left: 0px;
    top: 194.57px;
    background: #ffffff;
    border: 1px solid #cbc9c9;
    border-radius: 4px;
}
.btn-default {
    min-width: 135px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 11px 22px !important;
    gap: 10px;
    width: fit-content;
    height: 48px;
    background-color: $primary-color !important;
    border-radius: 40px;
    flex: none;
    order: 0;
    flex-grow: 0;
    border-radius: 40px !important;
    border: none !important;
    &:hover {
        background-color: #1f3b8c !important;
    }
}
.btn-outline {
    min-width: 135px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 11px 22px !important;
    gap: 10px;
    height: 48px;
    background: #ffffff !important;
    color: $primary-color !important;
    flex: none;
    order: 0;
    flex-grow: 0;
    border: 1px solid $primary-color !important;
    border-radius: 40px !important;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    font-weight: 600;
    cursor: pointer;
    &:hover {
        background: #e2eaff !important;
        color: #1f3b8c;
    }
    &:focus {
        color: #3d5cb8;
        background: #e2eaff;
        box-shadow: 0px 8px 16px rgba(61, 141, 219, 0.2);
    }
}
.btn-danger{
    color: #dc3545 !important;
    border-color: #dc3545 !important;
    box-shadow: none;
    margin-left: 10px;
    margin-right: 10px;
    &:hover{
        background-color: #ffeef0 !important;
        transform: none;
    }

}
.text-input {
    box-sizing: border-box;
    height: 48px;
    left: 209.5px;
    top: 83.48px;
    background: #ffffff;
    border: 1px solid #cbc9c9 !important;
    border-radius: 4px !important;
    &:hover {
        border: 1px solid #5a5c68 !important;
    }
    &:active {
        border: 1px solid #cbc9c9 !important;
    }
    &:focus {
        border: 1.5px solid #3d5cb8 !important;
        box-shadow: none !important;
    }
}
.error-message {
    font-weight: 400;
    font-size: 12px;
    color: #eb5757;
    line-height: 18px;
    // width: 208px;
    height: 18px;
    font-family: "Manrope";
    font-style: normal;
    padding-top: 5px;
    &::before {
        content: "i";
        background-color: #eb5757;
        color: #fff;
        padding: 1px 8px;
        border-radius: 50%;
        margin-right: 4px;
    }
}
