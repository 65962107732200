.text-input {
  margin-top: 5px;
  background-color: var(--primary-color);
}

.text-box {
  margin-top: 8px;
}

.error-message {
  color: var(--red);
  font-size: 12px;
}

.pagination-container {
  margin-top: 16px;
  float: right;
  padding-left: 24px;
  padding-right: 24px;
}

.pagination-container .pagination {
  display: flex;
  gap: 6px;
  list-style: none;
}

.pagination-container .page-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 4px;
}

.pagination > li > a {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination > li:hover {
  background-color: #ebf1ff;
}

.pagination > li.disabled,
li.disabled > a {
  background-color: transparent;
  cursor: not-allowed;
  color: #9f9f9f;
}

.pagination > .previous,
.pagination > .next {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 6px;
}

.page-item.active a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 6px;
  background-color: #ebf1ff !important;
  color: #3d5cb8 !important;
  box-shadow: none !important;
}

.breadcrumbs {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: "center";
}

.kmb-table th {
  color: #2d2f3a !important;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.kmb-table td {
  color: #5a5c68 !important;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.kmb-table tr,
.kmb-table td,
.kmb-table th {
  border: none;
}

.simple-captcha canvas {
  margin-left: -20px;
}

.simple-captcha {
  width: 100%;
}

/*# sourceMappingURL=style.css.map */

/* success model */

#success-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  /* padding: 1.2rem 4rem; */
}

.success-login-btn {
  padding: 1rem 3rem;
  border-radius: 2rem;
  border: none;
  background-color: #3d5cb8;
  color: white;
}

#success-modal-icon {
  display: flex;
  justify-content: center;
  padding-bottom: 0;
}

.success-login-btn {
  display: flex;
  justify-content: center;
}

.reg-first-text {
  font-size: 17px;
}

.reg-second-text {
  color: #37383b;
  font-weight: 400;
}

/* common list table */

.common-list-table {
  padding: 0px 24px 0px 24px !important;
  justify-content: space-between;
}

.table-list-heading {
  font-family: "Manrope";
  font-weight: 700;
  font-size: 14px !important;
  line-height: 22px;
  color: #2d2f3a !important;
  padding: 1rem;
}

.table-list-row {
  display: table-row;
  vertical-align: bottom;
}

/* .table-list-data {
    border: none;
    font-size: 14px;
    font-weight: 400;
    font-family: "Manrope";
    line-height: 22px;
    color: #5a5c68 !important;
    padding: 1rem !important;
} */
.table-list-data {
  border: none;
  font-size: 14px;
  font-weight: 400;
  font-family: "Manrope";
  line-height: 22px;
  color: #5a5c68 !important;
  padding: 1rem !important;
  word-wrap: break-word; /* To ensure long words break and wrap */
  max-width: 200px; /* Adjust the maximum width according to your needs */
}

/* .table-list-heading, 
.table-list-data{
text-align: center;
} */

.table-action-btn {
  background: none !important;
  border: None;
  border-radius: 40px;
  color: #3d5cb8;
  /* min-width: 82px; */
  max-height: 35px;
  /*  font-size: 14px;
    font-weight: 600;
    line-height: 26px;
    font-family: "Manrope"; */
  display: flex;
  /* gap: 4px !important; */
  align-items: center !important;
  /* padding: 11px 16px 11px 10px; */
}

.common-title {
  font-family: Manrope;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0px;
  text-align: left;
  position: absolute;
  top: 50px;
  z-index: 99;
}
.pagination li.active {
  color: #3d5cb8;
  font-weight: 600;
}

.common-warning-image {
  width: 50px;
}

.dropstart .more-icon.dropdown-toggle::before {
  content: none;
}
.dropstart .more-icon.dropdown-toggle {
  background: transparent;
  border: none;
  box-shadow: none;
  padding: 0;
}
.dropstart .more-icon.dropdown-toggle:active {
  background-color: #3d5cb8;
}
.regNo {
  font-size: 12.5px;
}
.marquee {
    white-space: nowrap;
    overflow: hidden;
    /* margin: 0 auto; */
    /* padding: 3em 0; */
    padding-top: 15px;
    padding-left: 40px;
    padding-right: 40px;
    width:100%;
    /* transform: rotate(-2deg) scale(1.1); */
    }
    
    .marquee__text {
    display: inline-block;
    font-size: 20px!important;
    color: rgb(245, 7, 47);
    }
    .marquee__text[data-direction="rtl"] {
    animation: marquee-rtl 15s linear infinite;
    } 
    
    .marquee__text[data-direction="ltr"] {
    animation: marquee-ltr 15s linear infinite;
    } 
    
    @keyframes marquee-rtl {
    0% { transform: translateX(0); }
    100% { transform: translateX(-100%); }
    }
    
    @keyframes marquee-ltr {
    0% { transform: translateX(-100%); }
    100% { transform: translateX(0); }
    }