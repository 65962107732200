.file-input::after {
    content: none !important;
}
.file-input::-webkit-file-upload-button {
    border: none !important;
}
.file-input:hover::-webkit-file-upload-button {
    background-color: transparent !important;
}
.input-file-icon {
    position: absolute;
    right: 12px;
    bottom: 25%;
}
.view-more {
    color: #3d5cb8;
    font-weight: 700;
}
