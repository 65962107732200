.inputStyle {
    width: 40px;
    height: 40px;
    margin: 0 5px;
    font-size: 18px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .otp-container-yard {
    display: flex;
    justify-content: center;
    margin-top: 8px;
    width: fit-content;
  }
  
  .otp-container-yard input {
    box-sizing: border-box;
    text-align: center;
    width: 47px !important;
    height: 48px;
    background: #ffffff;
    border: 1px solid #cbc9c9;
    border-radius: 4px;
    &:focus {
      border-color: #3d5cb8 !important;
    }
    &:focus-visible {
      border-color: #3d5cb8 !important;
    }
  }
  
  .otp-container-yard input:focus {
    border-color: #66afe9;
    outline: none;
    box-shadow: 0 0 5px rgba(102, 175, 233, 0.6);
  }
  
  /* Media Queries */
  // @media (max-width: 1200px) {
  //     .inputStyle, 
  //     .otp-container-yard input {
  //       width: 35px;
  //       height: 35px;
  //       font-size: 16px;
  //     }
    
  //     .otp-container-yard input {
  //       width: 38px !important;
  //       height: 47px;
  //     }
  //   }
    
  //   @media (max-width: 990px) {
  //     .inputStyle {
  //       width: 30px;
  //       height: 32px;
  //       font-size: 16px;
  //     }
    
  //     .otp-container-yard input {
  //       width: 21px !important;
  //       height: 33px;
  //     }
  //   }
    
  //   @media (max-width: 768px) {
  //     .inputStyle,
  //     .otp-container-yard input {
  //       width: 30px;
  //       height: 30px;
  //       font-size: 14px;
  //     }
    
  //     .otp-container-yard input {
  //       width: 62px !important;
  //       height: 50px;
  //     }
  //   }